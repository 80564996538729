export default class COMMON {
    init() {
        // 交差を検知したい要素
        const lazydisp = document.querySelectorAll('[data-lazydisp]');
        // IntersectionObserverのオプションを設定
        const options = {
            root: null,
            rootMargin: '20% 0px -20%',
            threshold: 0, // 閾値は0
        };

        // IntersectionObserverを呼び出し
        const observer = new IntersectionObserver(showElement, options);

        // lazydispクラスが付与されてる要素にたどり着いたらIntersectionObserverを実行
        lazydisp.forEach((elm) => {
            elm.classList.add('lazyhide');
            observer.observe(elm);
        });

        // 要素が表示されたら実行する動作
        function showElement(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // 要素にactiveクラスを付与
                    entry.target.classList.remove('lazyhide');
                }
            });
        }

        const elements = document.getElementsByClassName('js-hover-text');

        // 各要素に対して操作を行う
        for (let j = 0; j < elements.length; j++) {
            const p = elements[j];
            const text = p.innerText;
            let newText = '';

            for (let i = 0; i < text.length; i++) {
                const delay = (i * 0.04).toFixed(2);
                newText += `<span class="letter" style="animation-delay: ${delay}s">${text.charAt(
                    i
                )}</span>`;
            }

            p.innerHTML = newText;
        }
    }
}
