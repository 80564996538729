const [d] = [document];

const domReady = () =>
{
    console.log('トップページ内の処理');
};

export default function HOME () {
    d.addEventListener('DOMContentLoaded', domReady());
}
